import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useNavigate } from "react-router-dom";

export function CategoryList(props) {
  const navigate = useNavigate();

  props.recipes.sort(function(a, b) {
    var textA = a['name'];
    var textB = b['name'];
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
});

function handleRecipeClick() {
    navigate(`/recipe`)
}

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <List>
			{props.recipes.map((recipeElement)=>{
				return(
				<ListItem className="clickable" onClick={handleRecipeClick} key={props.recipes['name']}disablePadding>
            <ListItemButton>
              <ListItemIcon>
                  <RestaurantIcon/>
              </ListItemIcon>
			      {recipeElement.name}
            </ListItemButton>
          </ListItem>)
			})}
        </List>
    </Box>
  );
}
