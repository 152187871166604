import { useNavigate, useParams } from "react-router-dom";
import { Container, Paper, Typography } from "@mui/material";
import { IngredientsChecklist } from "../components/templateIngredientsChecklist.comp";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Divider from '@mui/material/Divider';
import { DirectionsList } from "../components/templateDirectionsList.comp";
import { Servings } from "../components/templateServings.comp";
import data from '../data/pearlCouscousSalad.json'

export function RecipePage(props) {
    const navigate = useNavigate()
    const recipe = data;
    
    function handleBackClick() {
        navigate(-1);
    }

	return (
		<Container>
			<Paper>
				<Typography variant="h2">
                <div>
                    <ArrowBackOutlinedIcon className="clickable navButton" fontSize="large" onClick={handleBackClick}/>
                </div>
                <div className="titleText">
					Pearl Couscous Salad
                </div>
                <Divider flexItem sx={{ pt: 2}}/>
				</Typography>
                <div className="flexContainer">
                <Servings data={recipe}/> 
                <Divider flexItem/>
                <IngredientsChecklist data={recipe}/>
                <Divider flexItem/>
                <DirectionsList data={recipe}/>
                </div>
			</Paper>
		</Container>
	);
}
