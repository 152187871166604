import * as React from 'react';
import List from '@mui/material/List';
import { Typography } from "@mui/material";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';


export function IngredientsChecklist(props) {

  return (
    <div>
    <Typography variant="h4" className="subtitleText" sx={{ pt: 3}}>
      <div className="subtitleText">
          Ingredients
      </div>
    </Typography >
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
    { props.data.ingredients.map((ingredientElement)=> {
      return(
      <ListItem>
        <ListItemAvatar>
          <Checkbox color="default"/>
        </ListItemAvatar>
        <ListItemText primary={ingredientElement.ingredientName} secondary={`${ingredientElement.amount} ${ingredientElement.unit}`}/>
      </ListItem>)
    })}
    </List>
    </div>
    );
}