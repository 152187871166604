import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from "@mui/material";

export function Servings() {
  return (
    <div>
    <Typography variant="h4" className="subtitleText" sx={{ pt: 3, pr: 3}}>
      <div className="subtitleText">
          Servings
      </div>
    </Typography >
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '8ch', pt: 1, pl: 2},
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-number"
          type="number"
          defaultValue="1"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </Box>
    </div>
  );
}
