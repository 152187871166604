import { Container, Paper, Typography } from "@mui/material";
import { CategoryCard } from "../components/templateCategoryCard.comp";
import { AddButton } from "../components/templateAddButton.comp";
import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";

export function HomePage() {
	const categories = [
		{ name: `Breakfast`, description: `Good morning sunshine!`, image: require("../assets/breakfast.jpg") },
		{ name: `Lunch`, description: `Give me a break`, image: require("../assets/lunch.jpg") },
		{ name: `Dinner`, description: `Wind down for the day`, image: require("../assets/dinner.jpg") },
		{ name: `Snacks`, description: `I'm feeling snacky`, image: require("../assets/snacks.jpg") },
		{ name: `Drinks`, description: `It's 5 o'clock somewhere`, image: require("../assets/drinks.jpg") },
	];

	const navigate = useNavigate();

	function handleClick(){
		navigate('/createrecipe')
	}

	return (
		<Container>
			<AddButton clickAction={handleClick}/>
				<Paper>
					<Typography variant="h2">
						<div className="titleText">
						Recipe Book
						</div>					
					</Typography>
					{categories.map((category) => {
						return (
							<CategoryCard
								category={category}
							/>
						);
					})}
				</Paper>
		</Container>
	);
}
