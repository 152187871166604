import * as React from 'react';
import List from '@mui/material/List';
import { Typography } from "@mui/material";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import { IngredientsAutocomplete } from './templateIngredientsAutocomplete.comp';


export function IngredientsForm(props) {

  return (
    <div>
    <Typography variant="h4" className="subtitleText" sx={{ pt: 3, pl: 3, pb: 2}}>
      <div className="subtitleText">
          Ingredients
      </div>
    </Typography >
    <IngredientsAutocomplete data={props}/>
    </div>
    );
}