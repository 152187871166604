import * as React from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export function CategoryCard(props) {
	const navigate = useNavigate()

	function handleCardClick() {
		navigate(`category/${props.category.name}`)
	}

	return (
		<Card className="clickable" onClick={handleCardClick}>
			<CardMedia sx={{ height: 350 }} image={props.category['image']} title={props.category['name']}/>
			<CardContent>
				<Typography variant="h5" component="div">
					{props.category['name']}
				</Typography>
				<Typography variant="h7">{props.category['description']}</Typography>
			</CardContent>
		</Card>
	);
}
