import { useNavigate, useParams } from "react-router-dom";
import { Container, Paper, Typography } from "@mui/material";
import { IngredientsForm } from "../components/templateIngredientsForm.comp";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Divider from '@mui/material/Divider';
import data from '../data/ingredients.json'

export function RecipeFormPage(props) {
    const navigate = useNavigate()
    const ingredients = data;
    
    function handleBackClick() {
        navigate(-1);
    }

	return (
		<Container>
			<Paper>
				<Typography variant="h2">
                <div>
                    <ArrowBackOutlinedIcon className="clickable navButton" fontSize="large" onClick={handleBackClick}/>
                </div>
                <div className="titleText">
					Create New Recipe
                </div>
                <Divider flexItem sx={{ pt: 2}}/>
				</Typography>
                <div className="flexContainer">
                <Divider flexItem/>
                <IngredientsForm data={ingredients}/>
                <Divider flexItem/>
                {/* <DirectionsForm/> */}
                </div>
			</Paper>
		</Container>
	);
}
