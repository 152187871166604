import { useParams, useNavigate } from "react-router-dom";
import { Container, Paper, Typography} from "@mui/material";
import { CategoryList } from "../components/templateCategoryList.comp";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Divider from '@mui/material/Divider';

const recipes = {
   'Breakfast':
     [ { name: `Scrambled Eggs` }, { name: `French Toast` }, { name: `Pancakes` }, { name: `Oatmeal` } ] ,
    'Lunch':
     [ { name: `Pearl Couscous Salad`}] ,
    'Dinner':
     [ { name: `Final Fantasy Leek Soup` }, { name: `New Year's Sloppy Joes` } ] ,
    'Snacks':
     [ { name: `Nachos` }, { name: `Salmon rolls` } ] ,
    'Drinks':
     [ { name: `Wisconsin-Style Grasshopper` } ] 
};

export function CategoryPage() {
  const {categoryName} = useParams()
	const navigate = useNavigate()

	function handleHomeClick() {
		navigate(`/`)
	}

  return (
		<Container>
			<Paper>
				<Typography variant="h2">
          <div>
          <ArrowBackOutlinedIcon className="clickable navButton" fontSize="large" onClick={handleHomeClick}/>
          </div>
          <div className="titleText">
					{categoryName}
          </div>
        <Divider flexItem sx={{ pt: 2}}/>
				</Typography>
						<CategoryList recipes={recipes[categoryName]}/>
			</Paper>
		</Container>
	);
  }