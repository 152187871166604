import "./App.css";
import "./custom.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CategoryPage } from "./pages/category.page";
import { HomePage } from "./pages/home.page";
import { RecipePage } from "./pages/recipe.page";
import { RecipeFormPage } from "./pages/recipeform.page";

function App() {

	const theme = createTheme({
		palette: {
		  primary: {
			main: '#00703D',
		  },
		},
	  });

	
	return (
		<ThemeProvider theme={theme}>
		<Router>
			<Routes>
				<Route exact path="/" element={<HomePage />} />
				<Route path="/category/:categoryName" element={<CategoryPage />} />
				<Route exact path="/recipe" element={<RecipePage />} />
				<Route path="/createrecipe" element={<RecipeFormPage />} />
			</Routes>
		</Router>
		</ThemeProvider>
	);
}

export default App;
