import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add'

export function AddButton(props) {
  return (
    <Box sx={{ '& > :not(style)': { m: 2} }} className="fixedButton" onClick={props.clickAction}>
      <Fab aria-label="add">
        <AddIcon />
      </Fab>
    </Box>
  );
}
