import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { Typography } from "@mui/material";

export function DirectionsList(props) {
//for ingredient in direction, replace text

  return (
    <div>
    <Typography variant="h4" className="subtitleText" sx={{ pt: 3, pl: 3}}>
      <div className="subtitleText">
          Directions
      </div>
    </Typography>
    <List sx={{ width: '100%', maxWidth: 900, bgcolor: 'background.paper', pl: 2 }}>
    { props.data.directions.map((directionsElement)=> {
      return(
      <ListItem>
        <ListItemAvatar>
          <RestaurantIcon/>
        </ListItemAvatar>
        <ListItemText primary={`${directionsElement.direction}`}/>
      </ListItem>)
      })}
    </List>
    </div>
  );
}